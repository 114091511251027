export const NetworkTableItems = [
  {
    name: "1",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "2",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "3",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "4",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "5",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "6",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "7",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "8",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
  {
    name: "9",
    imgURL: "/img/menuImages/sidebarImg.png",
  },
];
